@import "./src/assets/scss/global.scss";
.row {
  cursor: pointer;
}

.highlighted {
  color: rgba(#fff, 0.35);
}

.cell {
  position: relative;
  padding: fsize(5) fsize(65);
  transition: color 0.24s var(--transition-ease);

  &:not(:last-child):after {
    content: " ";
    display: inline-block;
    position: absolute;
    right: 0;
    width: 1px;
    height: 50%;
    margin-left: auto;
    background-color: rgba(#fff, 0.3);
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.link {
  text-decoration: none;
}

.lastCell {
  padding: 0 0 fsize(40) 0;
}
