@import "./src/assets/scss/global.scss";
.root {
  --text-size: #{fsize($size: 16, $min: 11px)};
  column-gap: 16px;

  @media (min-width: 1280px) {
    --text-size: #{fsize($size: 16, $min: 13px)};
  }

  @media (min-width: 1366px) {
    --text-size: #{fsize($size: 16, $min: 14px)};
  }
}

.rootMobile {
  --text-size: #{fsize($size: 14)};
  margin-top: fsize($size: 50, $unit: vh);
  margin-bottom: fsize($size: 50, $unit: vh);
}

.rootMobileNoBtn {
  margin-bottom: 0;
}

.label {
  margin-right: 5px;
  @include f-suisse-intl(
    (weight: 400)
  );
  color: var(--details-label, #fff);
  font-size: var(--text-size);
  letter-spacing: 0.02em;
  line-height: 165%;
}

.value {
  display: flex;
  color: var(--details-value, #939393);
  @include f-suisse-intl(
    (weight: 400)
  );
  font-size: var(--text-size);
  letter-spacing: 0.02em;

  @at-root .list & {
    line-height: 1.75;
  }
}

.list {
  @include reset-list;

  @media (max-width: $max-mobile) {
    align-items: flex-end;
  }
}

.mobileSection {
  padding: fsize(24) 0;
}

.hr {
  border: none;
  border-top: 1px solid var(--text, #fff);
  opacity: 0.2;
}

.linkMobileSection {
  margin-top: fsize($size: 50, $unit: vh);
  align-items: center;
}

.separator {
  margin-left: 16px;

  @include f-suisse-intl(
    (
      size: fsize(14, 12px),
    )
  );
  color: var(--details-label, #fff);
  line-height: 1.2;
}