@import "./src/assets/scss/global.scss";
.root {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;
}

.backdrop {
  position: absolute;
  inset: auto 0 0 0;
  height: var(--overlay-height);
  background-color: rgba(0, 0, 0 , 0.6);
}

.textSlot {
  position: relative;
  @include f-suisse-intl(
    (size: fsize(18), weight: 400)
  );

  margin-bottom: 25%;
  text-transform: uppercase;
  text-decoration: underline;
  overflow: hidden;
}

.iconSlot {
  margin-top: 50%;
}
