@import "./src/assets/scss/global.scss";
.container {
  :global .simplebar-content-wrapper {
    &:focus-visible {
      outline: none;

    }
  }

  @media (max-width: $max-mobile) {
    top: 0;
    bottom: 0;
    height: 100%;
    padding-bottom: 100px;

    :global .simplebar-content {
      padding-top: calc(var(--header-height) + 52px) !important;
    }
  }
}

.list {
  min-height: var(--container-frame-height);
}

.mobileListItem {
  margin-bottom: fsize(100);
}