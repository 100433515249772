@import "./src/assets/scss/global.scss";
@import "~normalize-scss/sass/normalize";

@include normalize();

@font-face {
  font-family: "Eurostile Next LT Pro Light Ext";
  src: url("./assets/fonts/EurostileNextLTPro-LightExt.eot");
  src: url("./assets/fonts/EurostileNextLTPro-LightExt.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/EurostileNextLTPro-LightExt.woff2") format("woff2"),
    url("./assets/fonts/EurostileNextLTPro-LightExt.woff") format("woff"),
    url("./assets/fonts/EurostileNextLTPro-LightExt.svg#EurostileNextLTPro-LightExt")
      format("svg");
  font-weight: 300;
  font-style: normal;
  font-stretch: expanded;
  unicode-range: U+0-10FFFF;
}

@font-face {
  // font-family: 'o';
  font-family: "Eurostile Next LT Pro Bold Ext";
  src: url("./assets/fonts/EurostileNextLTPro-BoldExt.eot");
  src: url("./assets/fonts/EurostileNextLTPro-BoldExt.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/EurostileNextLTPro-BoldExt.woff2") format("woff2"),
    url("./assets/fonts/EurostileNextLTPro-BoldExt.woff") format("woff"),
    url("./assets/fonts/EurostileNextLTPro-BoldExt.svg#EurostileNextLTPro-BoldExt")
      format("svg");
  font-weight: 700;
  font-style: normal;
  font-stretch: expanded;
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: "HelveticaNowText Light";
  src: url("./assets/fonts/HelveticaNowText-Light.eot");
  src: url("./assets/fonts/HelveticaNowText-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/HelveticaNowText-Light.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNowText-Light.woff") format("woff"),
    url("./assets/fonts/HelveticaNowText-Light.svg#HelveticaNowText-Light")
      format("svg");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family: "HelveticaNowText ExtraLight";
  src: url("./assets/fonts/HelveticaNowText-ExtraLight.eot");
  src: url("./assets/fonts/HelveticaNowText-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/HelveticaNowText-ExtraLight.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNowText-ExtraLight.woff") format("woff"),
    url("./assets/fonts/HelveticaNowText-ExtraLight.svg#HelveticaNowText-ExtraLight")
      format("svg");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family: "Helvetica Now Text";
  src: url("./assets/fonts/HelveticaNowText-Regular.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNowText-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNowText ExtraBold";
  src: url("./assets/fonts/HelveticaNowText-ExtraBold.eot");
  src: url("./assets/fonts/HelveticaNowText-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/HelveticaNowText-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNowText-ExtraBold.woff") format("woff"),
    url("./assets/fonts/HelveticaNowText-ExtraBold.svg#HelveticaNowText-ExtraBold")
      format("svg");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

// @font-face {
//     font-family: 'Myriad Pro';
//     src: url('./assets/fonts/MyriadPro-Regular.eot');
//     src: url('./assets/fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
//         url('./assets/fonts/MyriadPro-Regular.woff2') format('woff2'),
//         url('./assets/fonts/MyriadPro-Regular.woff') format('woff'),
//         url('./assets/fonts/MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
//     font-weight: 400;
//     font-style: normal;
//     font-stretch: normal;
//     unicode-range: U+0020-00FE;
// }

@font-face {
  font-family: "Eurostile Next LT Pro SmBdExt";
  src: url("./assets/fonts/EurostileNextLTPro-SmBdExt.eot");
  src: url("./assets/fonts/EurostileNextLTPro-SmBdExt.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/EurostileNextLTPro-SmBdExt.woff2") format("woff2"),
    url("./assets/fonts/EurostileNextLTPro-SmBdExt.woff") format("woff"),
    url("./assets/fonts/EurostileNextLTPro-SmBdExt.svg#EurostileNextLTPro-SmBdExt")
      format("svg");
  font-weight: 600;
  font-style: normal;
  font-stretch: expanded;
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: "HelveticaNowText Medium";
  src: url("./assets/fonts/HelveticaNowText-Medium.eot");
  src: url("./assets/fonts/HelveticaNowText-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/HelveticaNowText-Medium.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNowText-Medium.woff") format("woff"),
    url("./assets/fonts/HelveticaNowText-Medium.svg#HelveticaNowText-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family: "Eurostile Next LT Pro Extended";
  src: url("./assets/fonts/EurostileNextLTPro-Extended.eot");
  src: url("./assets/fonts/EurostileNextLTPro-Extended.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/EurostileNextLTPro-Extended.woff2") format("woff2"),
    url("./assets/fonts/EurostileNextLTPro-Extended.woff") format("woff"),
    url("./assets/fonts/EurostileNextLTPro-Extended.svg#EurostileNextLTPro-Extended")
      format("svg");
  font-weight: 400;
  font-style: normal;
  font-stretch: expanded;
  unicode-range: U+0-10FFFF;
}

// Helvetica Now Text
@font-face {
  font-family: "Helvetica Now Text";
  src: url("./assets/fonts/HelveticaNowText-ExtraBold.woff2") format("woff2"),
  url("./assets/fonts/HelveticaNowText-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Now Text";
  src: url("./assets/fonts/HelveticaNowText-Medium.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNowText-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./assets/fonts/HelveticaNowDisplay-Light.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNowDisplay-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

// Suisse Intl
@font-face {
  font-family: "Suisse Intl";
  src: url("./assets/fonts/SuisseIntl-Black.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("./assets/fonts/SuisseIntl-Bold.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("./assets/fonts/SuisseIntl-Medium.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("./assets/fonts/SuisseIntl-Book.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl-Book.woff") format("woff");
  font-weight: 450;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("./assets/fonts/SuisseIntl-Regular.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("./assets/fonts/SuisseIntl-Light.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("./assets/fonts/SuisseIntl-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("./assets/fonts/SuisseIntl-ThinItalic.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@function pxToRem($sizeInPx) {
  $result: calc(#{$sizeInPx} / var(--base-screen) * 100vw);
  @return $result;
}

// how to get vw: font-size (px) / viewport width (px) * 100 = font-size (vw)
html {
  font-size: pxToRem(16); // 16px / 375px * 100
  // font-size: 4.266666vw; // 16px / 375px * 100
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  overflow: hidden;
  overscroll-behavior: none;

  @media screen and (min-width: 620px) {
    font-size: pxToRem(9); // 9px / 768px * 100
    // font-size: 1.171875vw; // 9px / 768px * 100
  }

  @media screen and (min-width: 900px) {
    font-size: pxToRem(16); // 16px / 1920 * 100
    // font-size: 0.833333vw; // 16px / 1920 * 100
  }

  &.wait-cursor {
    cursor: wait;
  }
}

#root {
  min-height: inherit;
}

body {
  min-height: 100dvh;
  color: #fff;
  ::selection {
    background: #413f42;
  }
}

video {
  pointer-events: none;
}

.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe, .lenis.lenis-scrolling video {
  pointer-events: none;
}

.lenis {
  height: 100vh;
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.scrollbar {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9998;
  padding: 14px 0;
  height: 100%;
  width: calc(5px * 2);
  user-select: none;

  @media screen and (max-width: 620px) {
    display: none;
  }

  &.is--hidden {
    display: none;
  }

  &.is--hover {

    .inner {
      transform: translateX(0);
    }
  }

  &.active {

    .inner {
      transform: translateX(0);
    }
  }

  .inner {
    height: 100%;
    position: relative;
    transform: translateX(10px);
    transition: transform 0.4s var(--transition-ease);
  }

  .thumb {
    min-height: 80px;
    width: 5px;
    background-color: var(--color-headerlinks);
    opacity: 0.8;
    position: absolute;
    right: 4px;
    border-radius: 50px;
    cursor: grab;
    transition: width 0.4s ease, opacity 0.4s ease, background-color 0.4s ease;
    transform-style: preserve-3d;
    will-change: transform;

    &:hover {
      width: 8px;
      opacity: 1;
    }
  }
}

.backgroundHolder {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  z-index: -1;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .vimeoBg {
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .vimeoHolder {
    width: 100%;
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
  }

  .backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: url(./assets/img/home-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

a {
  color: #fff;
}

.desktopOnly {
  @media screen and (max-width: 640px) {
    display: none !important;
  }
}

.mobileOnly {
  @media screen and (min-width: 641px) {
    display: none !important;
  }
  @media screen and (max-width: 640px) {
  }
}

:root {
  --viewport-height: 100dvh;
  // --viewport-height: calc(var(--vh, 1vh) * 100);

  --base-width: 1920;
  --base-height: 1080;

  --base-screen: 375;

  --case-container: 25;
  --transition-duration: 1s;
  --transition-ease: cubic-bezier(0.06, 0, 0, 1);
  --transition-default: var(--transition-duration) var(--transition-ease);

  --element-radius: 2px;

  // Case
  --color-bookcall: currentcolor;
  --color-headerpanel: #{$panel-dark};
  --color-footerpanel: #{$panel-dark};
  --color-headerlinks: #c4c4c4;
  --color-headertext: #fff;
  --color-footerrtext: #fff;

  @media screen and (min-width: 620px) {
    --case-container: 60;
    --base-screen: 768;
  }

  @media screen and (min-width: 900px) {
    --case-container: 200;
    --base-screen: 1920;
  }
}

@media (max-width: $max-mobile) {
  :root {
    --base-width: 375;
    --base-height: 667;
  }
}