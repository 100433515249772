@import "./src/assets/scss/global.scss";
.icon {
  flex: 1 0 fsize(15, 16px);

  svg {
    @include responsive;
  }
}

.paragraph {
  @include f-suisse-intl(
    (
      size: fsize(9, 9px, 14px),
      weight: 400
    ),
    (
      letter-spacing: 0.28em
    )
  );

  text-transform: uppercase;
  line-height: 1.6;
}