@import "./src/assets/scss/global.scss";
$sizeConatinerMedium: 1400px;
$sizeContainerLarge: 1760px;

$sizeContainerModifiers: (
  "medium": $sizeConatinerMedium,
  "large": $sizeContainerLarge,
) !default;

:root {
  --container-frame-height: calc(83dvh - var(--header-height, 0) * 2);

  @media (max-width: $max-mobile) {
    --container-frame-height: calc(var(--viewport-height) - 212px);
  }
}

.Container {
  .simplebar-scrollbar {
    &:before {
      right: 0;
      left: 0;
      background: rgba(#fff, 0.2);
    }
  }

  .simplebar-track {
    &.simplebar-vertical {
      width: 2px;
    }
  }

  &--centered {
    @media screen and (min-width: $max-mobile) {
      @include marg-left($standardMargin);
      @include marg-right($standardMargin);
    }
  }

  &--frame {
    position: relative;
    top: calc(8vh + 56px);
    height: var(--container-frame-height);
    overflow: auto;

    @media (max-width: $max-mobile) {
      top: 110px;
      margin-right: 25px;
      margin-left: 25px;
    }
  }

  &__content {
    @media (max-width: $max-mobile) {
      $offset: 22px;
      $diff: $offset * 2 + 54px * 2 + $offset * 2;
      $top: $offset + 54px + $offset;

      position: relative;
      top: $top;
      height: calc(88.6vh - #{$diff});
      margin-right: 0;
      margin-left: 0;
      padding-right: 25px !important;
      padding-left: 25px !important;
      overflow-y: auto;

      &.ios {
        $diff: $offset * 2 + 54px * 2 + $offset * 2;
        height: calc(88.6vh - #{$diff});
      }
    }
  }
}

// Temp
.PageSection {
  height: inherit;
}