$baseWidth: 1920;
$baseHeight: 1080;

@media (max-width: $max-mobile) {
  $baseWidth: 375;
}

@function fsize($size, $min: null, $max: null, $unit: vw) {
  $baseSize: if($unit == 'vw', var(--base-width), var(--base-height));

  $value: calc(100#{$unit} / #{$baseSize} * #{$size});
  @if ($min == null and $max == null) {
    @return #{$value};
  }

  $min: if($min == null, $value, $min);
  $max: if($max == null, $value, $max);

  @return #{clamp($min, $value, $max)};
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }

  @content;
}

@mixin hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

[style*='--aspect']  > *:first-child {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@supports (--custom: property) {
  [style*='--aspect'] {
    position: relative;
  }
  [style*='--aspect']::before {
    content: '';
    display: block;
    padding-bottom: calc(100% / (var(--aspect)));
  }
  [style*='--aspect']  > *:first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}