@import "./src/assets/scss/global.scss";
.decorator {
  margin-bottom: fsize($size: 16, $unit: 'vh');
}

.content {
  color: var(--quote-text, #fff);
  @include f-suisse-intl(
    (
      style: italic,
      weight: 100
    ),
    (
      letter-spacing: 0.03em
    )
  );
  font-size: fsize(60);
  line-height: 110%;

  @media (max-width: $max-mobile) {
    font-size: fsize(29);
  }
}

.line {
  overflow: hidden;
  margin: -0.12em;
  padding: 0.12em;
}