@import "./src/assets/scss/global.scss";
.title {
  @include f-eurosite-light-ext;
  padding: fsize($size: 360, $unit: dvh) 0 fsize($size: 160, $unit: dvh);
  font-size: fsize(140, 116px);
  line-height: fsize(116, 92px);
  letter-spacing: -0.032em;
  text-align: center;

  @media screen and (max-width: $max-mobile) {
    @include f-eurosite-extended;
    @include f-size-sp(43, 44, -1.5);
    padding: fsize($size: 230, $unit: dvh) 0 fsize($size: 110, $unit: dvh);
  }
}