@import "./src/assets/scss/global.scss";
.container {
  --container-frame-height: calc(87dvh - var(--header-height));
  top: 5vh;

  :global(.simplebar-content) {
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (max-width: $max-mobile) {
      padding-top: 76px !important;
    }
  }

  :global(.simplebar-horizontal) {
    display: none;
  }

  @media (max-width: 640px) {
    height: 100%;
    padding-bottom: 110px;
  }
}

.containerBottom {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: $max-mobile) {
    flex-direction: column;
    margin-top: fsize(30);
  }
}

.title {
  margin: 0;
  @include f-eurosite-bold-ext;
  font-size: fsize(140);
  text-transform: uppercase;
  line-height: 0.78;
  letter-spacing: -0.04em;
  text-align: center;

  @media screen and (max-width: $max-mobile) {
    font-size: fsize(45);
    letter-spacing: -0.05em;
  }
}

.subtitle {
  margin: 0;

  @include f-suisse-intl((size: fsize(12, 10px)),
    (letter-spacing: 0.072em));
  text-align: center;
  line-height: 1.08;
  text-transform: uppercase;

  @media screen and (max-width: $max-mobile) {
    margin-top: fsize(5);
    font-size: fsize(10.6);
    letter-spacing: 0.1em;
  }
}

.content {
  margin: auto 0;

  @media screen and (max-width: $max-mobile) {
    margin-top: fsize(60);
  }
}

.buttons {
  margin-top: fsize(50);
  gap: fsize(55);

  @media screen and (max-width: $max-mobile) {
    gap: fsize(30);
    margin: fsize(40) 0 fsize(90);
  }
}

.buttonsDivider {
  height: fsize(16, 16px);
  width: 2px;
  background-color: #fff;
}

.blocks {
  gap: fsize(30);

  @media (max-width: $max-mobile) {
    flex-direction: column;
    margin-bottom: fsize(30);
  }
}

.block {
  :global(.BlockTitle) {
    @include f-suisse-intl((size: fsize(12, 11px),
        weight: 700),
      (letter-spacing: 0.03em,
        line-height: 1.05));

    &:before {
      content: "";
      position: relative;
      display: inline-block;
      width: fsize(10, 8px);
      height: fsize(8, 7px);
      background-color: #fff;
      margin-right: fsize(5);
    }
  }

  :global(.BlockBody) {
    color: rgba(255, 255, 255, 0.5);

    @include padd-top(5);
    @include f-suisse-intl((size: fsize(14, 12px)),
      (letter-spacing: 0.03em,
        line-height: 1.4285));

    a {
      color: rgba(255, 255, 255, 0.5);
      transition: color 0.3s;

      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}

.images {
  gap: fsize(20);

  @media (max-width: $max-mobile) {
    gap: 0;
    justify-content: space-around;
    width: 100%;
  }
}

.image {
  height: fsize($size: 56, $min: 56px);
  width: auto;
  user-select: none;

  @media (max-width: $max-mobile) {
    margin-top: fsize(30);
    height: fsize(65);
  }
}

.horizontalSeparator {
  height: 1px;
  width: 100%;
  background-color: rgba(#fff, 0.2);
}

.businessInquiry {
  margin: 0 0 fsize(50) 0;
  align-self: center;
}