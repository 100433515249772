@import "./src/assets/scss/global.scss";
.root {
  padding: fsize(20, 20px, 20px) fsize(24, 24px, 24px);

  @media screen and (max-width: $max-mobile) {
    padding: fsize(20) fsize(15);
  }

  &[data-apply-theme] {
    color: var(--color-footertext);
    background-color: var(--color-footerpanel);
  }
}

.wrapper {
  --cookie-footer-offset-y: 2px;
  --cookie-prompt-bottom: #{calc($site-gutter-y + var(--cookie-prompt-offset-y) + var(--cookie-footer-offset-y))};

  position: fixed;
  bottom: var(--cookie-prompt-bottom);

  @include sizing(right, 80);
  z-index: 1000;
  transition: bottom var(--transition-default);

  @media screen and (max-width: $max-mobile) {
    --cookie-prompt-bottom: #{calc($site-gutter-mob-y + var(--cookie-prompt-offset-y) + var(--cookie-footer-offset-y))};
    right: 0;
    left: 0;
    margin-right: 15px;
    margin-left: 15px;
  }
}

.button {
  appearance: none;
  padding-top: 0.2em;
  border: none;
  color: #555456;
  background: none;
  @include f-eurosite-bold-ext;
  font-size: fsize(8, 8px, 10px);
  letter-spacing: 0.144em;
  text-transform: uppercase;
  text-decoration: underline;
  text-underline-offset: 0.4em;
  text-decoration-thickness: 2px;
  cursor: pointer;
  transition-property: opacity, color;
  transition-duration: 0.12s;

  @media screen and (max-width: $max-mobile) {
    padding-top: 0.4em;
  }

  &:active,
  &:hover,
  &:focus {
    outline: none;
    color: #fff;
  }

  :global([data-apply-theme]) & {
    color: var(--color-footertext);
    opacity: 0.72;

    &:active,
    &:hover,
    &:focus {
      color: var(--color-footertext);
      opacity: 1;
    }
  }
}

.icon {
  composes: root from "../Icon.module.scss";
  width: fsize(15, 15px);
  height: fsize(15, 15px);
}

.text {
  @include f-suisse-intl(
    (
      size: fsize(9, 8px, 10px),
      weight: $fw-regular
    ),
    (
      letter-spacing: 0.252em
    )
  );

  margin-right: fsize(46, 0px, 48px);
  margin-left: fsize(15);
  padding-top: 0.2em;
  text-transform: uppercase;

  @media screen and (max-width: $max-mobile) {
    margin-left: fsize(10, 10px);
    margin-right: auto;
  }
}

.separator {
  align-self: center;
  width: 2px;
  margin: 0 fsize(20, 0px, 22px);
  height: fsize(12, 10px);
  background-color: #555456;

  :global([data-apply-theme]) & {
    background-color: var(--color-footertext);
    opacity: 0.2;
  }

  @media screen and (max-width: $max-mobile) {
    margin: 0 fsize(10);
  }
}