@import "./src/assets/scss/global.scss";
.root {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.icon {
  height: fsize(19);

  @media (max-width: $max-mobile) {
    height: fsize(15);
  }
}

.text {
  display: flex;
  position: relative;
  @include f-suisse-intl(
    (size: fsize(15), weight: 400)
  );
  text-transform: uppercase;

  @media (max-width: $max-mobile) {
    font-size: fsize(10);
  }
}

.bracket {
  position: absolute;
}