@import "./src/assets/scss/global.scss";
.root {
  display: inline-flex;
  width: max-content;
  padding: fsize(8);
  color: #000;
  background-color: #fff;
}

.rounded {
  border-radius: fsize(30);
}

.content {
  align-items: center;
  justify-content: space-between;

  @include f-suisse-intl(
    (
      size: fsize(11),
      weight: $fw-medium
    )
  );

  text-transform: uppercase;
}

.text {
  margin-bottom: -0.2em;
}