@import "./src/assets/scss/global.scss";
html:has(.about) {
  scroll-behavior: initial;
  scrollbar-width: none !important;

  body {
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }
  }
}