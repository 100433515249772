@import "./src/assets/scss/global.scss";
.root {
  box-sizing: border-box;
  align-items: center;
  max-width: fsize($size: 214);
  width: 100%;
  padding: fsize($size: 24);
  background-color: #2B292C;

  @media (min-width: $desktop) {
    max-width: fsize(240, 240px);
    padding: fsize(34);
  }
}

.content {
  margin-bottom: fsize(65, $unit: vh);
}