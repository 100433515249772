@import "./src/assets/scss/global.scss";
@import "./Spacing.scss";

*,
*::before,
*::after {
  margin: 0;
}

.linsa {
  bottom: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}

html:has(.case) {
  scroll-behavior: initial;
  scrollbar-width: none !important;

  body {
    // background-color: #1b1a1c;

    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }
  }

  // Note: remove this later
  // .SiteHeaderPanel,
  // .NavigateBackButton {
  //   background-color: #d6dac2 !important;
  // }

  // .case-nav {

  //   svg {

  //     rect {
  //       fill: #022d42 !important;
  //     }
  //   }
  // }

  // .SiteNavigation,
  // .SiteBranding,
  // .NavigateBackButton__arrow,
  // .HeaderTime,
  // .case-nav {
  //   color: #022d42;
  // }

  // .HamburgerMenu__line-inner,
  // .Time__separator {
  //   background-color: #022d42;
  // }
}

.case {
  font-family: "Suisse Intl", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.04em;
  line-height: 1.56;
  overflow: hidden;

  ::selection {
    background: var(--select-bgcolor);
    color: var(--select-textcolor);
  }

  p:not([class]) {
    margin: 0;
  }

  section {
    padding-top: map-get($spacing-sizes, 13);
    padding-bottom: map-get($spacing-sizes, 13);

    @media screen and (max-width: $max-mobile) {
      padding-top: map-get($spacing-sizes-m, 10);
      padding-bottom: map-get($spacing-sizes-m, 10);
    }
  }

  .case__content {
    background-color: #1b1a1c !important;
  }

  .img-placeholder {
    background-color: rgb(161, 161, 161);
    width: 100%;
    height: 40rem;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 100%;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 0;
    width: 100%;
    margin: 0 auto;
  }

  .grid-item {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12.5rem;
    position: relative;
  }

  .grid-item:not(:nth-child(3)):not(:nth-child(6))::after {
    content: "";
    position: absolute;
    top: 0;
    right: -10px;
    bottom: 0;
    width: 1px;
    background-color: #000;
  }

  .grid-item:nth-child(-n + 3)::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 1px;
    background-color: #000;
    z-index: 1;
  }

  #next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    // height: calc(100vh - var(--vh) + 10px);
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
    background-color: #1b1a1c;
    // padding: 0 !important;
    // position: fixed !important;
    // top: 0;
    // left: 0;
    // z-index: -1;
    // display: none;

    img {
      object-fit: cover;
    }

    .next__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .next__chapter {
      position: relative;
      z-index: 2;
      color: #fff;
    }
  }

  .case-btn {
    text-decoration: none;
    color: inherit;
    position: absolute;
    left: 0;
    bottom: 7px;

    @media screen and (max-width: $max-mobile) {
      width: 100%;
      position: relative;
      left: unset !important;
      bottom: unset !important;
    }
  }

  .swiper-wrapper {
    // transition-timing-function: cubic-bezier(0.33, 0.22, 0, 1.01) !important;
    @media (min-width: #{$desktop}) {
      transition-timing-function: cubic-bezier(0.67, 0, 0.06, 0.97) !important;
    }
  }
}

.masking-text {
  overflow: hidden;
  display: block;

  .line {
    will-change: transform;
  }
}

.clone-transition {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 98;
  pointer-events: none;
  // visibility: hidden;
  display: none;

  &.active {
    display: block;
  }

  // &.flipping {
  //   visibility: visible;
  // }

  .is--cloned {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

  }
}

.sticky-content {
  height: auto !important;
}