@import "./src/assets/scss/global.scss";
$contactInfoHolderMobileGap: 70px;

.ContactInfo {
  display: flex;
  width: 100%;
  overflow-x: auto;
  padding: fsize(30) 0;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    justify-content: space-evenly;
    padding: 0;
  }

  @media screen and (min-width: 1367px) {
    flex-grow: 1;
    flex-direction: row;
    width: auto;
  }

  .infoHolder {
    position: relative;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: $max-mobile) {
      flex-direction: column;
    }

    .label {
      align-self: flex-start;
      @include padd-right(10);

      @include f-suisse-intl(
        (
          size: fsize($size: 14, $min: 12px)
        ),
        (
          letter-spacing: 0.06em
        )
      );

      line-height: 1.5;
      overflow: hidden;
      white-space: nowrap;

      @media (max-width: $max-mobile) {
        margin-bottom: fsize(8);
        font-size: fsize(14);
      }
    }

    .content {
      @include f-suisse-intl(
        (
          size: fsize($size: 14, $min: 12px)
        ),
        (
          letter-spacing: 0.06em,
          line-height: 1.5
        )
      );

      color: rgba(255, 255, 255, 0.5);

      .row {
        white-space: nowrap;
        overflow: hidden;
      }

      a {
        color: rgba(255, 255, 255, 0.5);
        transition: color 0.3s;

        &:hover {
          color: rgba(255, 255, 255, 1);
        }
      }
    }

    &--inline {
      @media (max-width: 1280px) {
        flex-direction: column;
      }
    }
  }
}

.ContactInfo__contentContainer {
  display: flex;
}

.ContactInfoDivider {
  width: 1px;
  height: 100%;
  margin: 0 fsize(22);
  background-color: rgba(#fff, 0.2);
}

.contactInfoHolder {
  &__navBack {
    position: absolute;
    right: 0;
    top: 12px;
    overflow: hidden;
  }
}
