@import "./src/assets/scss/global.scss";
.root {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  background-color: #1B1A1C;
}

.icon {
  @at-root [data-device-type="mobile"] & {
    width: 80px;
    margin: 30.1333dvh 0 22px;
  }

  @at-root [data-device-type="tablet"] & {
    width: 114px;
    margin: 34.7571dvh 0 45px;
  }
}

.logo {
  @at-root [data-device-type="mobile"] & {
    width: 95px;
    margin-bottom: 5px;
  }

  @at-root [data-device-type="tablet"] & {
    width: 138px;
    margin-bottom: 14px;
  }
}

.title {
  @include f-suisse-intl((weight: 400));
  text-align: center;
  line-height: 1.7;

  @at-root [data-device-type="mobile"] & {
    font-size: 15px;
    letter-spacing: 0.012em;

  }

  @at-root [data-device-type="tablet"] & {
    font-size: 20px;
    letter-spacing: 0.022em;
  }
}