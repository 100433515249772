@import "./src/assets/scss/global.scss";
.root {
  position: fixed;
  top: 0;
  left: 0;
  inset: 0;

  font-size: 13px;
	--color-text: #fff;
	--color-bg: #000;
	--color-link: #fff;
	--color-link-hover: #fff;
  --img-width: #{fsize(148, 148px)};
	--img-ratio: 1 / 1.25;
	--img-offset-x: 20px;
	--img-offset-y: 20px;
	--img-border-radius: 0;
	--color-bg-content: rgba(255,255,255,0.05);
	--color-bg-demos: rgba(255,255,255,0.05);
}

.content__img {
  display: flex;
  justify-content: center;
  align-items: center;
	width: var(--img-width);
  // height: 237px;
	aspect-ratio: var(--img-ratio);
	border-radius: var(--img-border-radius);
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	overflow: hidden;
	will-change: transform, filter;
}

.content__img-inner {
	background-position: 50% 50%;
	// width: calc(100% + var(--img-offset-x));
  width: 100%;
  height: 100%;
	// height: calc(100% + var(--img-offset-y));
  // background-size: contain;
	position: absolute;
  background-size: 100%;
  top: 0;
  left: 0;
	// top: calc( -1 * var(--img-offset-y) / 2 );
	// left: calc( -1 * var(--img-offset-x) / 2 );
}