@import "./src/assets/scss/global.scss";
$panelSizing: 24px;

$panelSides: 25px;
$panelTop: 18px;

$panelPaddigModifiers: (
  "paddingSmall": $panelSizing,
  "paddingMedium": $panelSizing,
  "paddingLarge": $panelSizing,
) !default;

.Panel {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  border-radius: var(--element-radius);

  @each $modifier, $amount in $panelPaddigModifiers {
    &--#{$modifier} {
      //   padding: $amount;
      padding-left: $panelSides;
      padding-right: $panelSides;
      padding-top: $panelTop;
      padding-bottom: $panelTop;
    }
  }

  &--bottom {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 23px;
    padding-bottom: 23px;
  }

  &--dark {
    background-color: $panel-dark;
  }

  &--rounded {
    border-radius: 3px;
  }
}
