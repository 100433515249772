@import "./src/assets/scss/global.scss";
.root {
  height: 100%;

  ::selection {
    color: var(--user-selection-color, #fff);
    background: var(--user-selection-background, #413f42);
  }
}

.inner {
  box-sizing: border-box;
  position: relative;
  height: 100%;
  max-height: calc(90dvh - 80px);

  @include marg-left(240);
  @include marg-right($standardMargin);
  padding-top: fsize($size: 120, $unit: vh);

  @media (max-width: $max-mobile) {
    max-height: none;
    margin: 0;
    padding-right: 5px;
    padding-left: 5px;
    // margin-right: 25px;
    // margin-left: 25px;
    padding-top: fsize($size: 4, $unit: vw);
  }
}

.contentContainer {
  padding-top: fsize($size: 32, $unit: vw);
  overscroll-behavior: none;

  @media (max-width: $max-mobile) {
    padding-top: calc(var(--header-height));
    padding-right: 20px;
    padding-left: 20px;

    // Scrollbar
    // scroll-behavior: initial;
    // scrollbar-width: none !important;
    // -ms-overflow-style: none;

    // &::-webkit-scrollbar {
    //   width: 0 !important;
    //   height: 0 !important;
    // }
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);
  width: 59px;
  height: 54px;
  cursor: pointer;
  z-index: 1;

  @media (max-width: $max-mobile) {
    top: 12px;
    right: 15px;
  }
}

.closeStroke {
  position: absolute;
  background-color: var(--modal-close-btn-primary, #fff);
  height: 1px;
  width: 14px;
}

.quote {
  max-width: fsize(684);
  width: 100%;

  @media (max-width: $max-mobile) {
    max-width: none;
  }
}

.avatar {
  position: relative;
  margin: auto 0 fsize($size: 90, $unit: vh);

  @media (max-width: $max-mobile) {
    margin-top: fsize($size: 50, $unit: vh);
    margin-bottom: fsize($size: 50, $unit: vh);
  }

  @media (max-height: 960px) and (min-width: $max-mobile) {
    margin-bottom: fsize($size: 30, $unit: vh);
  }
}

.bodyWrapper {
  margin-left: auto;
  contain: layout;

  @include scrollbars(1px, var(--scrollbar-thumb-color, rgba(#fff, 1)), var(--scrollbar-track-color, #2B292C)) {
    &::-webkit-scrollbar-track {
      margin-top: var(--scrollbar-margin);
      margin-bottom: var(--scrollbar-margin);
    }
  };
  overflow: auto;
  flex-grow: 1;

  @media (max-width: $max-mobile) {
    overflow: visible;
  }
}

.body {
  max-width: fsize(605);
  margin: 0 auto;

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }

  h4 {
    margin: fsize(25) 0 fsize(12);
    color: var(--text, #ececec);
    @include f-suisse-intl(
      (weight: 900)
    );
    font-size: fsize($size: 12, $min: 10px);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    line-height: 120%;

    @media (max-width: $max-mobile) {
      margin: fsize(25) 0 fsize(8);
      font-size: fsize(12);
      line-height: 1.4;
    }
  }

  p {
    margin: 0 0 fsize(25);
    color: var(--text, #ececec);
    @include f-suisse-intl(
      (weight: 100)
    );
    font-style: italic;
    font-size: fsize($size: 18, $min: 13px);

    line-height: 140%;

    @media (max-width: $max-mobile) {
      font-size: fsize(17);
    }

    @media (min-width: 1366px) {
      font-size: fsize($size: 18, $min: 14px);
    }
  }

  ol,
  ul {
    margin: 0 0 0 fsize(15);
    padding: 0;
  }

  ul {
    margin-left: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;

      &:before {
        content: '-';
      }
    }
  }

  li {
    &::marker {
      @include f-helvetica-light;
      font-style: italic;
      font-size: fsize(17);
    }

    p {
      margin-left: fsize(10);
    }
  }
}

.signature {
  width: 100%;
  position: absolute;
  height: fsize(120, $min: 100px);
  bottom: fsize($size: 40, $unit: vh);

  @media (max-width: $max-mobile) {
    height: fsize(86);
    bottom: fsize($size: 34, $unit: vh);
  }
}

.textButton {
  [data-textbutton-text] {
    font-size: 10px;
  }
}