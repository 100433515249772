@import "./src/assets/scss/global.scss";
.root {
  display: flex;
}

.flex {
  display: flex;
}

.flexGap {
  --gap: 0px;
  --column-gap: var(--gap);
  --row-gap: var(--gap);

  margin: calc(var(--row-gap) / -2) calc(var(--column-gap) / -2);
  width: calc(100% + calc(var(--column-gap) + 1px));
  height: calc(100% + var(--row-gap));

  > * {
    margin: calc(var(--row-gap) / 2) calc(var(--column-gap) / 2);
  }
}

.wrap {
  flex-wrap: wrap;
}

// Direction

.directionColumn {
  flex-direction: column;
}

.directionColumnReverse {
  flex-direction: column-reverse;
}

.directionRowReverse {
  flex-direction: row-reverse;
}

// Justify Content

.justifyContentCenter {
  justify-content: center;
}

.justifyContentFlexEnd {
  justify-content: flex-end;
}

.justifyContentFlexStart {
  justify-content: flex-start;
}

.justifyContentSpaceAround {
  justify-content: space-around;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

.justifyContentSpaceEvenly {
  justify-content: space-evenly;
}

// Align Items

.alignItemsBaseline {
  align-items: baseline;
}

.alignItemsCenter {
  align-items: center;
}

.alignItemsFlexEnd {
  align-items: flex-end;
}

.alignItemsFlexStart {
  align-items: flex-start;
}

.alignItemsStretch {
  align-items: stretch;
}
