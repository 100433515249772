@import "./src/assets/scss/global.scss";
.MainFooter {
  transition: transform var(--transition-default);
  overflow: hidden;

  &.hide {
    transform: translateY(250%);
  }

  &.active {
    .Panel--dark {
      background-color: var(--color-footerpanel);
      color: var(--color-footertext);
    }
  }

  @include fixed {
    bottom: $site-gutter-y;

    @media screen and (max-width: $max-mobile) {
      bottom: $site-gutter-mob-y;
    }

    z-index: 9999;
  }

  .Panel {
    transition: background-color 0.3s ease;
    color: #fff;
  }

  .Panel--paddingMedium {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 23px;
    padding-bottom: 23px;
  }

  .fs {
    @include f-eurosite-sm-bd-ext;
    @include f-size-sp(8, 10, 2);

    margin-left: w(7);
  }

  .text {
    @include f-suisse-intl(
      (
        size: fsize($size: 7, $min: 7px),
        weight: 500
      ),
      (
        letter-spacing: 0.3em
      )
    );
    text-transform: uppercase;
    opacity: 0.4;
  }

  .mobileFoot {
    @include f-suisse-intl(
      (
        size: fsize(8, 10px),
        weight: 500
      ),
      (
        letter-spacing: 0.2em
      )
    );

    @media (min-width: 415px) {
        font-size: fsize(9);
    }

    text-align: center;
    width: 100%;
    opacity: 0.4;
  }

}