@use "sass:math";
@use "sass:map";

$fw-thin: 100;
$fw-ultra-light: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-black: 900;

$min-width: 10000000px;
$max-width: 1920px;
$max-mobile: 640px;
$desktop: $max-mobile + 1;
$max-ipad: 768px;

$standardMargin: 80;

$defaultFontAttributes: (
  weight: $fw-light
);

@mixin font-attributes($attributes: ()) {
  $mergedAttributes: map.merge($defaultFontAttributes, $attributes);

  @each $attribute, $value in $mergedAttributes {
    font-#{$attribute}: $value;
  }
}

@mixin f-eurosite-light-ext {
  font-family: "Eurostile Next LT Pro Light Ext", sans-serif;
  font-weight: 300;
}

@mixin f-eurosite-bold-ext {
  font-family: "Eurostile Next LT Pro Bold Ext", sans-serif;
  font-weight: 700;
}

@mixin f-eurosite-sm-bd-ext {
  font-family: "Eurostile Next LT Pro SmBdExt", sans-serif;
  font-weight: 600;
}

@mixin f-eurosite-extended {
  font-family: "Eurostile Next LT Pro Extended";
  font-weight: 400;
}

@mixin f-helvetica-light {
  font-family: "HelveticaNowText Light";
  font-weight: 300;
}

@mixin f-helvetica-extra {
  font-family: "HelveticaNowText ExtraLight";
  font-weight: 300;
}
@mixin f-helvetica-e-bold {
  font-family: "HelveticaNowText ExtraBold";
  font-weight: 800;
}
@mixin f-helvetica-medium {
  font-family: "HelveticaNowText Medium";
  font-weight: 500;
}

$fSuisseIntlDefaultRules: (
  letter-spacing: 0.08em
);

@mixin f-suisse-intl($attributes: (), $overrides: ()) {
  $rules:  map.merge($fSuisseIntlDefaultRules, $overrides);
  font-family: "Suisse Intl", sans-serif;
  @include font-attributes($attributes);

  @each $rule, $value in $rules {
    #{$rule}: $value;
  }
}

@function w($pixelSize) {
  $result: $pixelSize * math.div(100, $max-width);

  @return $result * 1vw * 1px;
}

@function wLimited($pixelSize) {
  @if $pixelSize <= 15 {
    @return $pixelSize * 1px;
  } @else {
    $result: $pixelSize * math.div(100, $max-width);

    @return $result * 1vw * 1px;
  }
}

// also letter spacing
@mixin f-size-sp($size, $line, $letter) {
  font-size: wLimited($size);
  line-height: wLimited($line);
  letter-spacing: wLimited($letter);
  @media screen and (min-width: $min-width) {
    font-size: $size * 1px;
    line-height: $line * 1px;
    letter-spacing: $letter * 1px;
  }
  @media screen and (max-width: $max-mobile) {
    font-size: $size * 1px;
    line-height: $line * 1px;
    letter-spacing: $letter * 1px;
  }
}

@mixin f-size($size, $line) {
  font-size: wLimited($size);
  line-height: wLimited($line);
  @media screen and (min-width: $min-width) {
    font-size: $size * 1px;
    line-height: $line * 1px;
  }
  @media screen and (max-width: $max-mobile) {
    font-size: $size * 1px;
    line-height: $line * 1px;
  }
}

@mixin padd($x, $y) {
  padding-top: w($x);
  padding-bottom: w($x);
  padding-left: w($y);
  padding-right: w($y);
  @media screen and (min-width: $min-width) {
    padding-top: $x * 1px;
    padding-bottom: $x * 1px;
    padding-left: $y * 1px;
    padding-right: $y * 1px;
  }
  @media screen and (max-width: $max-mobile) {
    padding-top: $x * 1px;
    padding-bottom: $x * 1px;
    padding-left: $y * 1px;
    padding-right: $y * 1px;
  }
}

@mixin padd-top($size) {
  padding-top: w($size);
  @media screen and (min-width: $min-width) {
    padding-top: $size * 1px;
  }
  @media screen and (max-width: $max-mobile) {
    padding-top: $size * 1px;
  }
}

@mixin padd-bottom($size) {
  padding-bottom: w($size);
  @media screen and (min-width: $min-width) {
    padding-bottom: $size * 1px;
  }
  @media screen and (max-width: $max-mobile) {
    padding-bottom: $size * 1px;
  }
}

@mixin padd-left($size) {
  padding-left: w($size);
  @media screen and (min-width: $min-width) {
    padding-left: $size * 1px;
  }
  @media screen and (max-width: $max-mobile) {
    padding-left: $size * 1px;
  }
}

@mixin padd-right($size) {
  padding-right: w($size);
  @media screen and (min-width: $min-width) {
    padding-right: $size * 1px;
  }
  @media screen and (max-width: $max-mobile) {
    padding-right: $size * 1px;
  }
}
@mixin marg-left($size) {
  margin-left: w($size);
  @media screen and (min-width: $min-width) {
    margin-left: $size * 1px;
  }
  @media screen and (max-width: $max-mobile) {
    margin-left: $size * 1px;
  }
}

@mixin marg-right($size) {
  margin-right: w($size);
  @media screen and (min-width: $min-width) {
    margin-right: $size * 1px;
  }
  @media screen and (max-width: $max-mobile) {
    margin-right: $size * 1px;
  }
}

@mixin marg-top($size) {
  margin-top: w($size);
  @media screen and (min-width: $min-width) {
    margin-top: $size * 1px;
  }
  @media screen and (max-width: $max-mobile) {
    margin-top: $size * 1px;
  }
}

@mixin marg-bottom($size) {
  margin-bottom: w($size);
  @media screen and (min-width: $min-width) {
    margin-bottom: $size * 1px;
  }
  @media screen and (max-width: $max-mobile) {
    margin-bottom: $size * 1px;
  }
}

@mixin sizing($attr, $size) {
  #{$attr}: w($size);
  @media screen and (min-width: $min-width) {
    #{$attr}: $size * 1px;
  }
  @media screen and (max-width: $max-mobile) {
    #{$attr}: $size * 1px;
  }
}
