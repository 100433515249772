@import "./src/assets/scss/global.scss";
.root {
  align-items: center;
  gap: fsize(6);
  min-height: 25px;
}

.statusIndicator {
  width: fsize(6, 5px);
  height: fsize(6, 5px);
  border-radius: 50%;
  background-color: #26ff00;
}

.text {
  @include f-suisse-intl(
    (
      size: fsize(12, 10px),
      weight: 400
    ),
    (
      line-height: 1,
      text-transform: uppercase
    )
  );
  padding-top: 0.1em;
}

.badge {
  @include f-suisse-intl(
    (
      size: fsize(9, 8px),
      weight: 700
    ),
    (
      line-height: 1.40,
      text-transform: uppercase,
      letter-spacing: 0.1em
    )
  );

  padding: 3px;
  color: #000;
  background-color: #fff;
}

.book {
  font-size: fsize(15);
  text-transform: uppercase;
  letter-spacing: 0.08em;
}