@import "./src/assets/scss/global.scss";
.image {
  width: fsize($size: 80, $min: 80px);
  height: fsize($size: 80, $min: 80px);
  margin-right: fsize(20);
  border-radius: 50%;
}

.name {
  @include f-suisse-intl(
    (
      weight: 500
    ),
    (
      letter-spacing: 0
    )
  );
  color: var(--avatar-name, #fff);
  font-size: fsize($size: 19, $min: 14px);
  line-height: 140%;

  @media (max-width: $max-mobile) {
    font-size: fsize(17);
  }
}

.title {
  @include f-suisse-intl(
    (weight: 100)
  );
  color: var(--avatar-title, #fff);
  font-size: fsize($size: 14, $min: 14px);
  line-height: 140%;

  @media (max-width: $max-mobile) {
    font-size: fsize(13);
  }
}