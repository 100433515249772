@import "./src/assets/scss/global.scss";
.root {
  width: fsize(37);
  height: fsize(24);
  color: var(--quote-decorator-primary, #2B292C);
  @include f-eurosite-bold-ext;
  font-size: fsize(32);

  font-style: normal;
  overflow: hidden;

  @media (max-width: $max-mobile) {
    font-size: fsize(24);
  }
}

.body {
  width: 100%;
  height: 100%;
  background-color: var(--quote-decorator-secondary, #fff);
}

.symbol {
  position: relative;
  top: 0.28em;
}