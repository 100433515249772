@import "./src/assets/scss/global.scss";
.root {
  @include f-suisse-intl(
    (
      size: fsize(10, 10px),
      weight: bold
    ),
    (
      letter-spacing: 0.15em,
      line-height: 1
    )
  );
  display: inline-flex;
  width: fit-content;
  padding: fsize(13, 13px) fsize(20, 20px);
  align-items: center;
  border-radius: var(--element-radius);
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  pointer-events: none;

  transition: filter 0.40s var(--transition-ease);
}

.interactive {
  pointer-events: auto;
}

.text {
  padding-top: 1%;
  padding-right: fsize(10, 10px);
  color: var(--btn-text-color, inherit);
  text-transform: uppercase;
  white-space: nowrap;
}

.icon {
  aspect-ratio: 1;
  // height: fsize(7, 7px);
  width: fsize(7, 7px);
}

.default {
  background-color: var(--btn-bg-color, #312F32);

  &:hover {
    filter: brightness(1.15);
  }
}