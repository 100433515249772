@import "./src/assets/scss/global.scss";
.root {
  position: relative;
  display: inline-flex;
  width: fit-content;
  @include f-eurosite-bold-ext;
  cursor: pointer;
  text-decoration: none;
  pointer-events: none;
  color: inherit;
}

.text {
  position: relative;
  top: -3px;
  font-size: fsize(10, 10px, 12px);
  letter-spacing: 0.18em;
  line-height: 1.2;
  text-transform: uppercase;
  white-space: nowrap;
}

.textInner {
  position: relative;
  overflow: hidden;
  color: inherit;
}

.textUpper {
  position: absolute;
}

.icon {
  margin-left: 9px;
  @include f-helvetica-e-bold;
  width: 22px;
  height: 22px;
  font-size: 10px;
  background-color: #F75703;
  border-radius: 50%;
}

.decorator {
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  overflow: hidden;
}

.decoratorInner {
  width: auto;
  height: inherit;
  background-color: currentColor;
}