@import "./src/assets/scss/global.scss";
.root {
  --text: #fff;

  display: flex;
  align-items: baseline;
  font-size: 11px;
  cursor: pointer;
  letter-spacing: 0.1em;
  user-select: none;
}

.Dropdown {
  position: relative;
  &Label {
    padding: 8px 0;
  }
  &Toggle {
    display: inline-flex;
    align-self: center;
    justify-self: center;
    padding: 8px;
    border: 0;
    background-color: transparent;
    outline: none;
    position: relative;
    text-transform: uppercase;

    &Inner {
      display: flex;
      justify-content: center;
      align-items: center;

      pointer-events: auto !important
    }
    &Text {
      line-height: 1.14;
      color: var(--text);
      display: inline-flex;
      padding-right: fsize(15, 15px, 18px);
      background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA4IDciPjxwYXRoIGQ9Ik04IC45MTJILjA1OWwzLjk3IDUuNTU5TDggLjkxWiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==) no-repeat right center /fsize(8, 8px, 9px) auto;
      white-space: nowrap;
      overflow: hidden;

      > div {
        border-bottom: 1px solid #fff;
      }
    }
  }
}

.fallback {
  @include f-helvetica-light;
  display: none;

  select {
    text-decoration: underline;
  }
}
