@import "./src/assets/scss/global.scss";
.root {
  @include f-suisse-intl();
  display: inline-flex;
  padding: 2px 0;
  align-items: center;
  font-size: 12px;

  @media screen and (max-width: #{$max-mobile}) {
    font-size: 16px;
  }

  letter-spacing: 0.062em;
}

.label {
  margin-right:0.64em;
}

.controlWrapper {
  position: relative;
  padding-right: fsize(16);
  text-decoration: underline;
  text-transform: uppercase;
  text-underline-offset: 3px;
  text-decoration-color: #fff;
  transition: text-decoration-color 0.32s ease;

  &:after {
    content: attr(data-value);
    transition: color 0.32s ease;
  }

  @at-root [data-is-hovering] & {
    text-decoration-color: #c4c4c4;

    &:after {
      color: #c4c4c4;
    }
  }
}

.control {
  appearance: none;
  position: absolute;
  height: 100%;
  width: 100%;
  // -webkit-appearance: none;
  // appearance: none;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA4IDciPjxwYXRoIGQ9Ik04IC45MTJILjA1OWwzLjk3IDUuNTU5TDggLjkxWiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==) no-repeat center right /auto 50%;
  color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  option {
    color: black;
    font-size: 16px;
  }
}