@import "./src/assets/scss/global.scss";
.AudioPlayer {
  &:not(&--loaded) {
    pointer-events: none;
  }
}

.AudioPlayerToggle {
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &--paused {
    position: absolute;
    left: 1%;
    top: 40%;
    width: 94%;
    height: 1px;
    background-color: currentColor;
  }
}

.AudioPlayerLabel {
  display: flex;

  @include f-eurosite-sm-bd-ext;
  @include f-size-sp(8, 10, 2);

  opacity: 1;
  text-transform: uppercase;
  transition: color 0.32s ease-out;

  @at-root .AudioPlayer--playing & {
    color: currentColor;
  }

  &__state {
    position: relative;
    margin-left: 2px;
  }
}

.AudioPlayerState {
  &--off {
    visibility: hidden;

    @at-root .AudioPlayer--playing & {
      visibility: visible;
    }
  }

  &--on {
    position: absolute;
    left: 0;

    @at-root .AudioPlayer--playing & {
      visibility: hidden;
    }
  }
}