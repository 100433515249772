@import "./src/assets/scss/global.scss";
.root {
  position: absolute;
  inset: 0;
}

.overlay {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0 , 0.6);
  text-transform: uppercase;
}