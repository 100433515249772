@import "./src/assets/scss/global.scss";
.root {
  display: flex;
  align-items: baseline;
  user-select: none;
  pointer-events: auto;
  padding: 0.5rem;
  margin: -0.5rem;

  &:not([data-touch-device]):hover {
    svg {
      circle {
        fill: var(--details-label, currentcolor);
        stroke: var(--details-label, currentcolor);
      }

      path {
        fill: var(--details-popup-bg, #1b1a1c);
      }
    }
  }
}