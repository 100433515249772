@import "./src/assets/scss/global.scss";
.root {
  overflow: hidden;
  border-top: 1px solid rgba(#fff, 0.2);
  border-bottom: 1px solid rgba(#fff, 0.2);
  color: rgba(#fff, 80%);

  @include f-suisse-intl(
    (
      size: 1rem,
      weight: 300
    ),
    (
      letter-spacing: 0.0625em
    )
  );
}

.header {
  padding: 20px 0;
  font-size: 0.75rem;
  text-transform: uppercase;
  cursor: pointer;
}

.toggle {
  position: relative;
  display: inline-flex;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  overflow: hidden;

  &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }
}

.toogleLabel {
  display: inline-flex;
}

.bodyInner {
  padding: fsize(25) 0 fsize(50);

  @media screen and (min-width: $max-mobile) {
    padding: fsize(35) 0 fsize(55);
  }
}