@import "./src/assets/scss/global.scss";
.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 9px;
}

.line {
  pointer-events: none;

  &:last-child {
    margin-bottom: 0;
  }
}

.lineInner {
  width: 100%;
  height: 1px;
  background-color: var(--line-color);
  transition: background-color 0.2s;
}

.interactiveArea {
  left: -10px;
  top: -13px;
  position: absolute;
  width: calc(100% + 20px);
  height: calc(100% + 26px);
  cursor: pointer;
}