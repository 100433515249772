@import "./src/assets/scss/global.scss";
.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.dimmer {
  position: absolute;
  inset: 0;
}

.noPointerEvents {
  pointer-events: none;
}

.invisible {
  background-color: transparent;
}

.hasOverlayMask {
  overflow: hidden;
}

