@import "./src/assets/scss/global.scss";
.Work {
  @include hide-scrollbar;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100dvh;
  padding-top: calc(#{$site-gutter-y} + var(--header-height) + 65px);
  padding-bottom: calc(#{$site-gutter-y} + var(--footer-height) + 4dvh);
  overflow: auto hidden;
  overscroll-behavior: none;

  @media (max-width: #{$max-mobile}) {
    margin-right: 15px;
    margin-left: 15px;

    padding-top: calc(#{$site-gutter-mob-y} + var(--header-height) + 65px);
    padding-bottom: calc(#{$site-gutter-mob-y} + var(--footer-height) + 15px);
  }

  &__content-wrapper {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    gap: 3px;
  }

  .title {
    @include f-eurosite-light-ext;
    font-size: fsize(42);
    margin-top: auto;
    position: relative;
    color: #fff;

    @media screen and (min-width: #{$max-mobile + 1}) {
      @include f-size-sp(140, 140, 1);
    }

    .lineMask {
      margin-bottom: w(-30);
    }

    .no {
      @include f-eurosite-extended;
      font-size: 11px;
      font-weight: 400;
      letter-spacing: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      $size: calc(100vw/1920 * 50);
      $clamped: clamp(16px, #{$size}, #{$size});
      line-height: 1;

      color: #fff;
      overflow: hidden;
      margin-left: fsize(4);
      margin-top: -0.1em;

      > span {
        margin-top: -0.05em;
        margin-right: 0.05em;
        margin-left: 0.05em;
      }

      @media (max-width: #{$max-mobile}) {
        font-size: fsize(18, 18px);

        span {
          font-size: fsize(14, 14px);
        }
      }

      @media screen and (min-width: #{$max-mobile + 1}) {
        margin-left: fsize(0);
        font-size: fsize(40, 40px);

        span {
          font-size: fsize(32, 32px);
        }
      }
    }
  }

  &__slide {
    position: relative;
    flex-shrink: 0;
    width: fsize(456);
    height: 100%;
    min-width: 320px;
    aspect-ratio: 0.6 / 1;
    width: auto;

    @media (max-width: #{$max-mobile}) {
      min-width: 0;
    }

    &-title {
      display: flex;
      flex-direction: column;
      width: 60vw;
      flex-shrink: 0;
      contain: layout;

      @media (max-width: #{$max-mobile}) {
        width: calc(100dvw - 92px);
      }
    }
  }

  &__dropdown {
    @include f-suisse-intl;
    font-size: 12px;
    letter-spacing: 0.062em;

    &-cloneWrapper {
      height: var(--header-height);
      margin-left: fsize(60);
    }

    &-wrapper {
      width: fit-content;
      overflow: hidden;
      margin-top: 28px;
      font-size: fsize(14, 14px);

      &--mobile {
        margin-top: 0;
        top: calc(var(--header-height, 0) + 58px);
        left: 15px;
        position: absolute;
        transform: translateY(-50%);
      }

      @media screen and (min-width: #{$max-mobile + 1}) {
        display: flex;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }

  &__contextmenu-item {
    text-transform: uppercase;
    overflow: hidden;
  }

  @keyframes parallax {
    to {
      transform:
        translateX(calc(var(--parallax-speed, 0) * 100px));
    }
  }

  .parallax__layer {
    animation: parallax linear;
    animation-timeline: scroll(x);
  }
}

.ContextMenu__Works {
  @include scrollbars(2px, rgba(255, 255, 255, 0.24), transparent);

  @media (min-width: #{$max-mobile + 1}) and (max-height: 760px) {
    max-height: 68px;
    overflow: auto;
  }
}

.Drag__Works {
  @include f-eurosite-bold-ext;

  position: absolute;
  inset: 0;
  display: flex;
  width: fsize(100, 74px);
  height: fsize(100, 74px);
  justify-content: center;
  align-items: center;
  margin: auto 14dvh auto auto;
  border-radius: 50%;
  color: #fff;
  background-color: #2B292C;
  font-size: fsize(8, 8px);
  pointer-events: none;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  z-index: 1;
}
