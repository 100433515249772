@import "./src/assets/scss/global.scss";
.cell {
  vertical-align: top;
  padding: fsize(5) 0 fsize(8) 0;

  &:first-child  {
    padding-right: fsize(36);
  }
}

.lastCell {
  padding: 0 0 fsize(45) 0;
}

.title {
  display: flex;
  align-items: center;

  &:before {
    content: "";
    display: block;
    width: 1px;
    height: 1rem;
    margin-right: fsize(36);
    background-color: rgba(#fff, 0.3);
  }
}

.category {
  color: rgba(#fff, 0.5);
  margin-left: fsize(36);
  white-space: nowrap;
}