@import "./src/assets/scss/global.scss";
.contextMenu {
  --text: var(--details-popup-text, #{rgba(#fff, 0.4)});
  --menu-background: var(--details-popup-bg, #2B292C);
  --menu-active-color: var(--text);

  padding: 11px 0;
}

.contextMenuItem {
  padding: 4px 22px 4px 18px;
  font-size: fsize($size: 13, $min: 12px);
  line-height: 1;
  cursor: default;
}

.icon {
  aspect-ratio: 1 / 1;
  width: fsize($size: 16, $min: 12px);
  margin-left: 0.25rem;
  color: var(--details-label, #fff);

  circle {
    stroke: currentColor;
  }

  path {
    fill: currentColor;
  }

  &[data-popover-open] {
    circle {
      fill: #fff;
      stroke: #fff;
    }

    path {
      fill: #1b1a1c;
    }
  }
}