@import "./src/assets/scss/global.scss";
.Time {
  display: flex;
  align-items: center;
  @media screen and (max-width: $max-mobile) {
    &.HeaderTime {
        display: none;
    }
  }

  &__indicator {
    @include f-eurosite-light-ext;
    font-size: 10px;
    margin-right: 8px;
    line-height: 1;

    > span {
      position: relative;
      top: -2px;
      display: inline-flex;
      width: 3px;
      height: 3px;
      border-radius: 50%;
    }
  }

  &__label {
    @include f-eurosite-light-ext;
    font-size: 8px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  &__separator {
    width: 13px;
    height: 1px;
    margin: 0 8px;
    background-color: #fff;
  }

  &__digital {
    margin-right: 3px;
    line-height: 1;
  }
}