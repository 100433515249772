@import "./src/assets/scss/global.scss";
.root {
  // width: fsize(370);

  @media (max-width: $max-mobile) {
    width:100%;
  }
}

.card {
  &:after {
    content: " ";
    position: absolute;
    inset: auto 0 0 0;
    height: 4px;
    background-color: #fff;
  }
}

.title {
  padding-top: 0.15em;
  font-size: fsize(32, 24px);
  line-height: 1;

  @media (max-width: $max-mobile) {
    font-size: fsize(22);
  }

  @media (min-width: 414px) and (max-width: 1024px) {
    font-size: fsize(26);

  }
}

.subtitle {
  @include f-suisse-intl(
    (
      size: fsize(14, 14px),
      weight: 400
    ),
    (
      line-height: 1.28
    )
  );

  @media (max-width: $max-mobile) {
    font-size: fsize(13, 13px);
  }

  text-align: center;
}

.decoratorContainer {
  margin: fsize(36) 0;
  overflow: hidden;
}

.decorator {
  width: 1px;
  height: fsize(37);
  background-color: rgba(#fff, 0.3);
}

.sup {
  position: relative;
  top: -0.4em;
  margin-left: 4px;

  [class^="index-module"] {
    transform: translateY(-1%);
  }

  @include f-suisse-intl(
    (
      size: fsize(15, 12px),
      weight: 400
    ),
    (
      line-height: 1.25,
      letter-spacing: 0
    )
  );

  @media (max-width: $max-mobile) {
    font-size: fsize(12, 12px);
  }
}