@import "variables/colors";
@import "variables/typography";
@import "utils";

// $site-gutter-y: 65px;
$site-gutter-mob-y: 25px;
$site-gutter-y: 5dvh;

@mixin fixed($centered: true) {
  & {
    position: fixed;
    // width: 100%;
    z-index: 999;

    @if $centered {
      //   margin-right: auto;
      //   margin-left: auto;

      @include marg-left(80);
      @include marg-right(80);
      right: 0;
      left: 0;

      @media screen and (max-width: $max-mobile) {
        @include marg-left(15);
        @include marg-right(15);
        // @include marg-left(25); // default
        // @include marg-right(25); // default
      }
    }

    @content;
  }
}

@mixin reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin responsive($max: null) {
  width: 100%;
  height: auto;
  object-fit: cover;

  @if ($max !=null) {
    max-width: $max;
  }

  @content;
}