@import "./src/assets/scss/global.scss";
.body {
  position: relative;
}

.footer {
  margin-top: fsize(32);
  margin-right: auto;
  margin-left: auto;
  @include f-eurosite-light-ext;
  font-size: fsize(11);
  font-weight: $fw-semi-bold;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
  text-decoration-line: underline;
}

.imageContainer {
  position: relative;
  width: fsize(220);
  height: fsize(316);
}

.imageMask {
  position: absolute;
  inset: 0;
  top: auto;
  height: 40%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nameContainer {
  position: absolute;
  bottom: fsize(20);
  width: 100%;
}

.name {
  max-width: fsize(200);
  @include f-eurosite-light-ext;
  font-weight: 100;
  font-size: fsize(28);
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  line-height: 1.16;
}

.industry {
  position: relative;
  @include f-suisse-intl((size: fsize(9)), (letter-spacing: 0.09em));
}

.decorator {
  align-self: stretch;
  align-items: center;
  @include f-helvetica-light;
  font-size: fsize(10);
  width: fsize(50);
}

.decoratorLeft {
  font-size: fsize(14);
  &:before {
    content: "";
    width: 2px;
    height: fsize(40);
    margin-right: fsize(12);
    background: white;
  }
}

.decoratorRight {
  &:after {
    content: "";
    width: 2px;
    height: fsize(40);
    margin-left: fsize(12);
    background: white;
  }
}

.separator {
  position: absolute;
  bottom: fsize(-12);
  color: #F65600;
  @include f-eurosite-extended;
  font-weight: 600;
  font-size: fsize(20);
  text-transform: uppercase;
}

.rounded {
  width: fsize(13);
  height: fsize(13);
  border-radius: 50%;
  border: 1px solid #fff;
  line-height: 0.5;
}

.overlay {
  z-index: 999;
}