@import "./src/assets/scss/global.scss";
.root {
  top: auto;
  bottom: 0;
  align-items: flex-end;
  background-color: transparent;
  z-index: 9;
  // height: calc(var(--viewport-height) - 100px);
  height: calc(var(--viewport-height) - var(--header-height) - 42px);
  overflow: visible;

  @media (min-width: 641px) {
    height: calc(95vh - 72px);
  }
}

.contentWrapper {
  position: absolute;
  top: auto;
  bottom: 0;
  width: 100%;
  height: inherit;
  background-color: var(--modal-bg, #1A191A);
}