@import "./src/assets/scss/global.scss";
.root {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  --scrollbar-track-color: #2B292C;
  --scrollbar-thumb-color: #fff;

  @media screen and (max-width: 768px) {
    --scrollbar-track-color: transparent;
    --scrollbar-thumb-color: transparent;
  }

  :global(.simplebar-vertical) {
    width: 6px;
    background: var(--scrollbar-track-color);
  }

  :global(.simplebar-scrollbar) {
    &:before {
      right: 0;
      left: 0;
      background: var(--scrollbar-thumb-color);
      opacity: 1;
    }
  }
}

.hideScrollbars {
  :global(.simplebar-vertical) {
    display: none;
    background: var(--scrollbar-track-color);
  }
}