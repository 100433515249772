@import "./src/assets/scss/global.scss";
.Home {
  width: 100vw;
  height: 100dvh;
  top: 0;
  left: 0;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $max-mobile) {
    position: fixed;
  }

  .centerHolder {
    text-align: center;

    &--blend {
      mix-blend-mode: difference;
    }

    .lineMaskSm {
      position: relative;
      overflow: hidden;
      @include padd-bottom(1);
      @include marg-bottom(-1);
    }

    .lineMask {
      position: relative;
      overflow: hidden;
      @include padd-bottom(10);
      @include marg-bottom(-10);

      @media screen and (max-width: $max-mobile) {
        @include padd-bottom(0);
      }

      &:nth-child(2) {
        @include padd-right(3);
      }
    }

    .est {
      @include f-eurosite-light-ext;
      font-size: fsize(10, 8px);
      @include marg-bottom(23);
      letter-spacing: 0.27em;

      @media screen and (max-width: $max-mobile) {
        @include f-size-sp(10, 16, 1);
      }
    }

    .mainText {
      @include f-eurosite-light-ext;
      font-size: fsize(140, 116px);
      line-height: fsize(116, 92px);
      letter-spacing: -0.032em;

      @media screen and (max-width: $max-mobile) {
        @include f-eurosite-extended;
        @include f-size-sp(43, 44, -1.5);
      }
    }

    .tagline {
      max-width: 45vw;
      @include f-eurosite-light-ext;
      margin: fsize(40) auto;

      font-size: fsize(10, 8px);
      text-transform: uppercase;
      letter-spacing: 0.27em;

      @media screen and (max-width: $max-mobile) {
        max-width: none;
        margin-top: fsize(20);
        margin-bottom: fsize(60);
        @include f-size-sp(10, 16, 1);
      }
    }

    .buttonHolder {
      @include marg-top(7);

      @media screen and (max-width: $max-mobile) {
        margin-left: fsize(5);
        padding-top: 0px;
        padding-bottom: 5px;
      }

    }
  }
}