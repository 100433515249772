@import "./src/assets/scss/global.scss";
.NavigateBackButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2b292c;
  cursor: pointer;
  border-radius: var(--element-radius);

  &__link {
    display: flex;
    text-decoration: none;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &__arrow {
    display: flex;
    align-items: center;
    width: fsize(10, 10px, 11px);
    aspect-ratio: 1 / 1.8;
    text-align: center;
    user-select: none;
  }

  &--medium {
    width: 60px;
    height: 54px;
    font-size: 14px;
    top: -0.1em;
  }

  &--large {
    width: 94px;
    height: 112px;
    font-size: 16px;
  }
}