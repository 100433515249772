@import "./src/assets/scss/global.scss";
.root {
  --text: #{rgba(#fff, 0.5)};
  --menu-background: transparent;
  --menu-active-color: #fff;
  --menu-active-background: #201F21;
  --menu-border: var(--menu-background);

  box-sizing: border-box;
  // border-radius: 8px;
  overflow: hidden;
  background-color: var(--menu-background);
  border: 1px solid var(--menu-border);
}

.itemList {
  display: flex;
  flex-direction: column;
  // padding: 5px;
  outline: none;

  button {
    margin: 0;
  }
}

.item {
  @include f-helvetica-light;
  @include f-suisse-intl;
  appearance: none;
  padding: 8px 16px;
  border: none;
  color: var(--text);
  background-color: var(--background-color);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.075em;
  outline: none;
  text-align: left;
  cursor: pointer;

  transition: color 0.3s;

  @at-root .compressed & {
    padding: 3px 16px 3px 4px;
    font-size: 11px;

    &:first-child {
      padding-top: 0;
    }
  }


  &:active,
  &:focus,
  &:hover {
    color: var(--menu-active-color);
    // background-color: var(--menu-active-background);
  }
}
