@import "./src/assets/scss/global.scss";
.root {
  --name-size: #{fsize(60, 38px)};
  @include f-helvetica-light;

  margin-right: 14px;
  line-height: 1.2;
}

.interactive {
  cursor: pointer;
}

.name {
  @include f-eurosite-light-ext;

  position: relative;
  font-size: var(--name-size);
  text-transform: uppercase;
  letter-spacing: -0.01em;
  overflow: hidden;
  padding-left: var(--name-offset-left, 0);
  // padding-right: var(--name-offset-right, 0);
}

.nameInner {
  &:after {
    content: attr(data-name);
    position: absolute;
    top: 0;
    left: 0;
    // right: var(--name-offset-right, 0);
    width: 100%;
    height: 100%;
    color: transparent;
    background: linear-gradient(#f65600 0 100%) no-repeat left /0% 100%;
    background-clip: text;
    --webkit-background-clip: text;

    transition: background-size 0.32s ease-in-out;
  }

  @at-root .hover & {
    &:after {
      background-size: 100% 100%;
    }
  }
}

.border {
  width: 4px;
  height: 80%;
  background-color: #fff;
}

.borderWrapper {
  display: flex;
  align-items: center;
  align-self: stretch;
  overflow: hidden;
}

.index {
  margin-right: auto;
  margin-left: fsize(32);
  overflow: hidden;
}

.indexBody {
  display: flex;
  align-items: center;
}

.indexLabel {
  font-size: fsize(19);
  margin-right: 4px;
}

.indexValue {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 50%;
  width: fsize(14);
  height: fsize(14);
  font-size: fsize(10);
  line-height: 1;
  letter-spacing: 0;
  text-rendering: optimizeLegibility;
}

.industry {
  margin-right: fsize(32);
  margin-left: auto;
  font-size: fsize(12);
  line-height: 1.65;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  overflow: hidden;
}

.slash {
  padding-left: fsize(24);
  font-size: fsize(60);
  overflow: hidden;
}

.role {
  padding: 1.4em 0 0 fsize(24);
  @include f-eurosite-light-ext;
  font-size: fsize(10);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  overflow: hidden;
  font-weight: 600;
}

.portrait {
  width: fsize(220);
  height: auto;
  aspect-ratio: 1 / 1.45;
}

.overlay {
  z-index: 999;
}