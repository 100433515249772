@import "./src/assets/scss/global.scss";
.AnalogClock {
  position: relative;
  width: 23px;
  height: 23px;
  background-color: #2b292c;
  border-radius: 50%;
  overflow: hidden;

  &__body {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
  }
}

.AnalogClockHand {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 1px;
  background-color: #fff;
  transform-origin: left top;

  &__hours {
    width: 30%;
  }

  &__minutes {
    width: 35%;
  }
}