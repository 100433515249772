.spacing {
  display: block;
}

$spacing-sizes: (
  1: 0.9375rem, // 15px
  2: 1.125rem, // 18px
  3: 1.75rem, // 28px
  4: 2.5rem, // 40px
  5: 5rem, // 80px
  6: 5.625rem, // 90px
  7: 7.5rem, // 120px
  8: 8.75rem, // 140px
  9: 10rem, // 160px
  10: 12.5rem, // 200px
  11: 13.125rem, // 210px
  12: 15rem, // 240px
  13: 18.75rem, // 300px
  14: 25rem // 400px
);

$spacing-sizes-m: (
  1: 0.625rem, // 10px
  2: 0.75rem, // 12px
  3: 0.875rem, // 14px
  4: 1.25rem, // 20px
  5: 1.5rem, // 24px
  6: 2rem, // 32px
  7: 2.5rem, // 40px
  8: 3.125rem, // 50px
  9: 4.375rem, // 60px
  10: 5rem, // 80px
  11: 7.5rem, // 120px
  12: 7.5rem, // 120px
  13: 7.5rem, // 120px
  14: 7.5rem // 120px
);

@each $key, $value in $spacing-sizes {
  // Spacing Vertical
  .my-#{$key} {
    margin-top: $value;
    margin-bottom: $value;

    @media screen and (max-width: 620px) {
      $value2: map-get($spacing-sizes-m, $key);
      margin-top: $value2;
      margin-bottom: $value2;
    }
  }

  .py-#{$key} {
    padding-top: $value;
    padding-bottom: $value;

    @media screen and (max-width: 620px) {
      $value2: map-get($spacing-sizes-m, $key);
      padding-top: $value2;
      padding-bottom: $value2;
    }
  }

  // Spacing Horizontal
  .mx-#{$key} {
    margin-left: $value;
    margin-right: $value;

    @media screen and (max-width: 620px) {
      $value2: map-get($spacing-sizes-m, $key);
      margin-left: $value2;
      margin-right: $value2;
    }
  }

  .px-#{$key} {
    padding-left: $value;
    padding-right: $value;

    @media screen and (max-width: 620px) {
      $value2: map-get($spacing-sizes-m, $key);
      padding-left: $value2;
      padding-right: $value2;
    }
  }
  
  // Spacing Top
  .mt-#{$key} {
    margin-top: $value;

    @media screen and (max-width: 620px) {
      $value2: map-get($spacing-sizes-m, $key);
      margin-top: $value2;
    }
  }

  .pt-#{$key} {
    padding-top: $value;

    @media screen and (max-width: 620px) {
      $value2: map-get($spacing-sizes-m, $key);
      padding-top: $value2;
    }
  }

  // Spacing Bottom
  .mb-#{$key} {
    margin-bottom: $value;

    @media screen and (max-width: 620px) {
      $value2: map-get($spacing-sizes-m, $key);
      margin-bottom: $value2;
    }
  }

  .pb-#{$key} {
    padding-bottom: $value;

    @media screen and (max-width: 620px) {
      $value2: map-get($spacing-sizes-m, $key);
      padding-bottom: $value2;
    }
  }

  // Spacing Left
  .ml-#{$key} {
    margin-left: $value;

    @media screen and (max-width: 620px) {
      $value2: map-get($spacing-sizes-m, $key);
      margin-left: $value2;
    }
  }

  .pl-#{$key} {
    padding-left: $value;

    @media screen and (max-width: 620px) {
      $value2: map-get($spacing-sizes-m, $key);
      padding-left: $value2;
    }
  }

  // Spacing Right
  .mr-#{$key} {
    margin-right: $value;

    @media screen and (max-width: 620px) {
      $value2: map-get($spacing-sizes-m, $key);
      margin-right: $value2;
    }
  }

  .pr-#{$key} {
    padding-right: $value;

    @media screen and (max-width: 620px) {
      $value2: map-get($spacing-sizes-m, $key);
      padding-right: $value2;
    }
  }
}