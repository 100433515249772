@import "./src/assets/scss/global.scss";
$cardBorderColor: rgba(#b4aeb7, 0.2);

.CardAppear {
  height: 60vh;
  width: 100%;
  position: absolute;
}

.Card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // border-top: 4px solid #fff;
  overflow: hidden;
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  user-select: none;
  cursor: pointer;

  &:before {
    content: " ";
    top: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    border-top: 4px solid #fff;
  }

  .cardWrapper {
    box-sizing: border-box;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    height: var(--content-height);
    position: absolute !important;
    width: 100%;
    bottom: 0;
  }

  &__header {
    @include padd-top(20);
    @include padd-right(20);
    @include padd-left(20);

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-grow: 1;
    margin-top: 6px;

    @media screen and (min-width: #{$max-mobile + 1}) {
      @include padd-top(30);
      @include padd-right(30);
      @include padd-left(30);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  &__body {
    @include padd-right(20);
    @include padd-bottom(20);
    @include padd-left(20);

    margin: auto 0 2px 0;

    @media screen and (min-width: #{$max-mobile + 1}) {
      @include padd-right(30);
      @include padd-bottom(30);
      @include padd-left(30);
    }

    &[data-empty] {
      display: none;
    }
  }

  &__footer {
    position: relative;
  }

  &__reel {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    transition: opacity 0.3s var(--transition-ease);
  }

  &--border {
    border-right: 1px solid $cardBorderColor;
    border-left: 1px solid $cardBorderColor;
  }
}

.CardIndex {
  font-family: "Helvetica Now Text", sans-serif;
  font-weight: 300;
  display: flex;
  align-items: center;
  padding: 0 2px 0.1em 0;
  overflow: hidden;
  line-height: 1.2;
  z-index: 1;

  > div {
    display: flex;
    align-items: center;
  }

  &__label {
    @include padd-right(2);
    font-size: fsize(19, 16px);

    @media (max-width: #{$max-mobile}) {
      font-size: fsize(16);
    }
  }

  &__index {
    display: flex;
    padding: 0.08em;
    font-size: fsize(16, 15px);
    width: fsize(16, 15px);
    height: auto;


    @media (max-width: #{$max-mobile}) {
      font-size: fsize(14);
      width: fsize(15);
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.CardTags {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1;
}

.CardTag {
  @include f-suisse-intl;
  // @include f-size(10, 12);
  font-size: fsize(10);
  line-height: 1.25;

  text-transform: uppercase;
  letter-spacing: 0.15em;
  overflow: hidden;

  @media (max-width: #{$max-mobile}) {
    font-size: fsize(9);
  }
}

.CardTitle {
  @include f-suisse-intl;
  font-size: 28px;
  letter-spacing: 0;

  @media screen and (min-width: #{$max-mobile + 1}) {
    font-size: fsize(40, 32px);
    line-height: 1;
    margin-bottom: fsize(10);
  }

  margin: 0;
  overflow: hidden;
  transition: opacity 0.48s var(--transition-ease);

  @at-root .Card--overlay-visible & {
    opacity: 0;
  }
}

.CardYear {
  @include f-eurosite-light-ext();
  @include f-size(8, 10);

  text-transform: uppercase;
  letter-spacing: 0.26em;
  overflow: hidden;

  transition: opacity 0.48s var(--transition-ease);

  @at-root .Card--overlay-visible & {
    opacity: 0;
  }
}

.CardImage {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 0.5624;
  object-fit: cover;
  object-position: bottom;
  overflow: hidden;

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: #{$max-mobile}) {
      height: 100%;
    }
  }
}
