@import "./src/assets/scss/global.scss";
@function pxToRem($sizeInPx) {
  $result: calc(#{$sizeInPx} / var(--base-screen) * 100vw);
  @return $result;
}

.SiteHeader {
  pointer-events: none;

  > * {
    pointer-events: all;
  }

  @include fixed {
    top: $site-gutter-y;

    @media screen and (max-width: $max-mobile) {
      top: $site-gutter-mob-y;
      box-sizing: border-box;
      margin-left: 0;
      margin-right: 0;
      padding: 0 15px;
    }

    z-index: 999;
  }

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: $max-mobile) {
    width: 100%;
  }

  .RightPanel {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: var(--header-height);
    overflow: hidden;

    .button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      [data-textbutton-text] {
        font-size: 10px;
      }
    }

    &.active {
      .HeaderTime {
        transform: translateY(calc(var(--header-height) * -1));
      }

      .button {
        transform: translateY(0%);
        // color: var(--color-bookcall);
        transition: transform var(--transition-default);
      }
    }

    &.activeclock {
      .AnalogClock__body {
        background-color: var(--color-headerpanel);
      }
    }

    .HeaderTime {
      transform: translateY(0%);
      transition: transform var(--transition-default);
    }

    .button {
      position: absolute;
      right: 0;
      top: 0;
      transform: translateY(var(--header-height));
      transition: none;
    }
  }
}

.SiteHeaderPanel {
  display: flex;
  align-items: center;
  width: 455px;
  transition: background-color 0.3s ease, width var(--transition-default);

  @media (min-width: 428px) and (max-width: #{$desktop}) {
    padding-top: 22px;
    padding-bottom: 22px;
  }

  @media screen and (max-width: $max-mobile) {
    width: calc(100vw - 30px);
  }

  &.activecolor {
    background-color: var(--color-headerpanel);

    .HamburgerMenu {
      --line-color: var(--color-headertext) !important;
    }

    .SiteNavigation {
      color: var(--color-headertext);
    }
  }

  &.active {
    background-color: var(--color-headerpanel);

    &:not(.activecolor) {
      // min-width: 197px;
      width: 197px;

      @media screen and (max-width: $max-mobile) {
        width: pxToRem(200);
      }

      .SiteBranding {

        svg {
          width: 50px;
        }

        .hide {
          opacity: 0;
        }

        .s {
          transform: translateX(-40px);
        }

        .slash {
          opacity: 1;
          transform: translateX(7px);
        }
      }
    }

    .SiteBranding {
      svg {
        path {
          fill: var(--color-headertext);
        }
      }
    }

    .HamburgerMenu {
      --line-color: var(--color-headertext) !important;
    }

    .SiteNavigation {
      color: var(--color-headertext);
    }
  }

  &__wrapper {
    position: relative;

    @media screen and (max-width: $max-mobile) {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .SiteBranding {
    line-height: 0;

    svg {
      max-width: 129px;
      width: 100%;
      max-height: 10px;

      path {
        fill: currentColor;
        transition: fill 0.3s var(--transition-ease),
          opacity 0.3s var(--transition-ease),
          transform 0.3s var(--transition-ease);
      }

      .slash {
        opacity: 0;
        transform: translateX(35px);
      }
    }
  }
}

.SiteNavigation {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  @include f-suisse-intl();
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  transition: color 0.3s ease;
  white-space: nowrap;

  @media (min-width: 428px) {
    font-size: 16px;
  }

  @media (min-width: #{$desktop}) {
    font-size: 13px;
  }

  &__active {
    position: relative;
    margin-right: 15px;
    text-transform: lowercase;
    text-align: right;
    line-height: 1.5;
    z-index: 10;
    cursor: pointer;
  }
}

.SiteHeaderLinks {
  &__wrapper {
    position: absolute;
    right: 0;
    top: calc(100% + 2px);
    overflow: hidden;
    z-index: 9;

    &.active {
      .Panel--dark {
        background-color: var(--color-headerpanel);
      }

      .Panel {
        --color-headerlinks: #c4c4c4;
      }
    }

    .Panel {
      --color-headerlinks: #c4c4c4;
    }
  }
}

.SiteHeaderNavigateBack {
  &__wrapper {
    position: absolute;
    left: 0;
    top: calc(100% + 2px);
    overflow: hidden;

    @media screen and (max-width: $max-mobile) {
      left: inherit;
      right: 0;
      transform: scaleX(-100%);

      .NavigateBackButton__arrow {
        transform: scaleX(-100%) !important;
      }
    }
  }
}

.SiteHeaderLinks {
  @include reset-list;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;

    @include f-suisse-intl();
    font-size: 13px;
    line-height: 2.28;
    transition: all 0.3s;

    &[data-disabled] {
      pointer-events: none;
    }

    &::before {
      content: "";
      position: absolute;
      width: 2px;
      height: 2px;
      background-color: rgba(196, 196, 196, 0);
      left: -8px;
      transition: all 0.5s;
      pointer-events: none;
    }

    &:not([data-disabled]):hover {
      transform: translateX(5px);

      &::before {
        content: "";
        position: absolute;
        width: 2px;
        height: 2px;
        background-color: rgba(196, 196, 196, 1);
      }
    }
  }
}
