@import "./src/assets/scss/global.scss";
.root {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #2b292c;
  pointer-events: none;
  z-index: 10000;
  cursor: pointer;
}

.label {
  @include f-eurosite-bold-ext;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  letter-spacing: 0.12em;
}
