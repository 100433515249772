@import "./src/assets/scss/global.scss";
// $modalBgColor: #2b292c;
$modalBgColor: #090809;

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  overflow: hidden;
  background-color: $modalBgColor;

  &__letterbox {
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: $modalBgColor;
    z-index: 3;

    &.letterbox-1 {
      top: 0;
    }

    &.letterbox-2 {
      bottom: 0;
    }
  }

  &__content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 91.66666vw;
    // height: 90vh;
    width: 100%;
    height: 100%;
    background-color: $modalBgColor;

    @at-root .Modal--fullscreen & {
      width: 100vw;
      height: 100vh;
    }
  }

  &__backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $modalBgColor;
    background-color: transparent;
    z-index: -1;
  }
}