@import "./src/assets/scss/global.scss";
.root {
  display: inline;
  position: relative;
  margin: 0;
  line-height: 0;
  content: paint;
}

.preSrc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(5px);
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.base {
  width: inherit;
  height: inherit;
}